<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="faqs"
      :permissions="permissions"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
    />
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="السؤال"
            placeholder="السؤال"
            type="text"
            v-model="faq.question"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="الجواب"
            placeholder="الجواب"
            type="text"
            v-model="faq.answer"
            validation-rules="required"
          />
        </div>
      </div>
    </tm-popup>
    <vs-popup
      title="تفاصيل السؤال"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <vs-row>
        <label>المعرف :</label>
        <vs-input
          placeholder=""
          name="title"
          disabled
          color="#5E445A"
          class="w-full mt-3"
          v-model="faq.id"
        />
      </vs-row>


      <vs-row class="mt-2">
        <label>السؤال :</label>
        <vs-input
          placeholder=""
          name="title"
          disabled
          color="#5E445A"
          class="w-full mt-3"
          v-model="faq.question"
        />
      </vs-row>
      <vs-row class="mt-2">
        <label>الجواب :</label>
        <vs-input
          placeholder=""
          name="title"
          disabled
          color="#5E445A"
          class="w-full mt-3"
          v-model="faq.answer"
        />
      </vs-row>

      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from "@/app/shared/shared-components/ag-grid/AgTable";
import {RepositoryFactory} from "@/app/donations-website/donations-repositories/donationsRepositoryFactory";
import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell";
import utilities from '../../../shared/utilities/index';
import TmInput from "../../../shared/shared-components/TmInput";
import TmPopup from "../../../shared/shared-components/TmPopup";

const faqsRepo = RepositoryFactory.get("faqsRepository");

export default {
  name: "FaqsMain",
  components: {
    AgTable,
    TmInput,
    TmPopup
  },
  data() {
    return {
      gridApi: null,
      popupActiveDetails: false,
      dialogTitle: '',
      faq: {
        id: null,
        question: '',
        answer: ''
      },
      faqs: [],
      isLoading: true,
      gridOptions: null,
      permissions: {
        add: 'store_faqs',
        delete: 'destory_faqs',
        edit: 'update_faqs'
      }
    };
  },
  methods: {
    fetchAllFaqs() {
      faqsRepo.fetchAllFaqs().then((data) => {
        this.faqs = data;
        this.isLoading = false;
      });
    },
    createRecord() {
      utilities.initObjectMembers(this.faq);
      this.dialogTitle = 'اضافة سؤال و جواب';
      this.openPopup();
    },

    editRecord(faq) {
      this.dialogTitle = 'تعديل';
      Object.assign(this.faq, faq);
      this.openPopup();
    },

    openPopup() {
      this.$refs.popup.open();
    },
    onSubmit() {
      this.isLoading = true;
      if (this.faq.id !== null) {
        this.updateFaq(this.faq);
      } else {
        this.storeFaq(this.faq);
      }
    },

    storeFaq(faq) {
      faqsRepo.saveFaq(faq).then((response) => {
        this.$refs.popup.endLoading();
        this.$refs.popup.close();
        this.faqs.unshift(response);
      });
    },

    updateFaq(faq) {
      faqsRepo.updateFaq(faq).then((response) => {
        this.$refs.popup.endLoading();
        this.$refs.popup.close();
        let index = this.faqs.map((faq) => {
          return faq.id;
        }).indexOf(response.id);
        this.faqs[index] = response;
        this.rerender();
      });
    },
    rerender() {
      this.gridApi.setRowData(this.faqs);
    },
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          checkboxSelection: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'السؤال',
          field: 'question',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الجواب',
          field: 'answer',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          valueGetter:
            function getObject(params) {
              return params.data.id;
            },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (valueGetter, rowIndex) {
              self.deleteFaqs(valueGetter, rowIndex);
            },
            editRecord: function (record) {
              self.editRecord(record);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete'],
            permissions: self.permissions
          },
          sortable: false
        },
      ];
    },
    viewRecordDetails(record) {
      this.faq = record;
      this.popupActiveDetails = true;
    },
    deleteFaqs(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      faqsRepo.deleteFaq(ids).then(() => {
        if (isMultiple)
          this.fetchAllFaqs();
        else {
          this.faqs.splice(index, 1);
          this.faqs = [...this.faqs];
        }
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllFaqs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>
.vs-popup--title h3 {
  color: white !important;
  padding: 12px 20px !important;
}

</style>
